import * as SimpleBar from "simplebar"

function initToggle() {
    function getToggled() {
        return JSON.parse(localStorage.getItem("toggled")) || []
    }

    function toggle(ev, selector) {
        if (ev.target) {
            const parent = ev.target.closest(selector)

            const id = ev.target.id
            const toggled = getToggled()

            if (ev.target.checked) {
                if (!toggled.find(i => i === id)) {
                    toggled.push(id)
                }

                parent.classList.add("active")
            } else {
                parent.classList.remove("active")

                if (toggled && toggled.find(i => i === id)) {
                    toggled.splice(toggled.indexOf(id), 1)
                }
            }

            const collapses = [...new Set(toggled)]

            localStorage.setItem("toggled", JSON.stringify(collapses))

            if (window.course_id) {
                fetch(`/api/v1/public/courses/${window.course_id}/collapses`, {
                    headers: {
                        "Content-Type": "application/json"
                    },
                    method: "POST",
                    body: JSON.stringify({ collapses })
                })
            }
        }
    }

    window.toggle = toggle

    document.querySelectorAll(".title_reason").forEach(title => {
        title.innerHTML = title.innerHTML.replaceAll(
            ":date",
            title.classList.contains("title_reason_red") ? `<span>${title.dataset.date}</span>` : title.dataset.date
        )
    })

    getToggled().forEach(id => {
        if (id === "program") {
            return
        }

        const input = document.querySelector(`#${id}`)

        if (input) {
            setTimeout(() => {
                if (!input.checked) {
                    input.click()
                }
            }, 200)
        }
    })

    const open = el => {
        const module = el.closest(".course__preview-module:not(.active)")

        if (module) {
            const input = module.querySelector(".collapse-input")
            input.click()

            setTimeout(() => {
                open(module)
            }, 0)
        }
    }

    const openActive = active => {
        open(active)

        const scroll = document.querySelector(".course-program-container__scroll")
        const isOnlyProgram = document.querySelector(".only-program")

        if (isOnlyProgram) {
            active.scrollIntoView()
            return
        }

        if (scroll) {
            const { top: scrollTop } = scroll.getBoundingClientRect()
            const { top: activeTop } = active.getBoundingClientRect()

            const bar = new SimpleBar(scroll)

            bar.getScrollElement().scrollTo({
                top: activeTop - scrollTop,
                behavior: "smooth"
            })
        }
    }

    const active = document.querySelector(".course__preview-module-handler_lesson_active")

    if (window.innerWidth <= 1300) {
        const programInput = document.querySelector(".course-program-input")

        if (programInput) {
            programInput.checked = false

            programInput.addEventListener("click", () => {
                if (active) {
                    setTimeout(() => {
                        active.scrollIntoView({
                            block: "center",
                            behavior: "smooth"
                        })
                    }, 300)
                }
            })
        }

        if (active) {
            openActive(active)
        }
    } else if (active) {
        openActive(active)
    }
}

window.initToggle = initToggle

initToggle()
